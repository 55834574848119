<template>
  <div class="conteudos_tematicos_bg">
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <img
          width="20%"
          src="../../assets/conteudo_genero_01.png"
          class="mt-4 title__img"
        />
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              title="As mulheres atingidas na luta pela reparação"
              title_alignment="start"
              borderColor="#426E51"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p class="pa-3 mb-2 my-auto" style="background-color: #F3F3F3;">
                  “A ideia de liberdade é inspiradora. Mas o que significa? Se
                  você é livre em um sentido político, mas não tem comida, que
                  liberdade é essa? A liberdade de morrer de fome? (Angela
                  Davis)”
                </p>
                <p>
                  Se sonhar é direito, como defende o escritor uruguaio Eduardo
                  Galeano, as mulheres marianenses que margeiam o rio Gualaxo do
                  Norte viviam esse direito de forma legítima, até que o
                  rompimento da barragem de Fundão pôs fim a muitos sonhos e
                  aspirações. Mulheres com identidades diversas e que ocupam
                  muitos lugares na sociedade: donas de casa,
                  pescadoras<sup>1</sup>, garimpeiras, benzedeiras<sup>2</sup>,
                  lavradoras, psicólogas, advogadas, bordadeiras, artesãs,
                  cozinheiras, professoras, costureiras, doceiras, quitandeiras,
                  mães, filhas, irmãs, amigas, dentre tantas outras. As mulheres
                  atingidas são plurais e indefiníveis. O ato de existir é a
                  constante reinvenção de suas histórias e de todas as pessoas
                  que com elas convivem.
                </p>
                <p>
                  Se, antes do rompimento da barragem, essas mulheres já
                  representavam uma grande força capaz de congregar ao redor de
                  si familiares, vizinhas e amigas para lutar por seus direitos,
                  o desastre-crime intensificou ainda mais essa capacidade,
                  evidenciando o protagonismo<sup>3</sup> que essas mulheres
                  possuem na condução de suas existências e resistências.
                </p>
                <p>
                  Em relação ao protagonismo das mulheres na luta por Direitos
                  <sup>4</sup>, é de Mirella Lino, integrante mais nova da
                  Comissão de Atingidos pela Barragem de Fundão (CABF) e
                  moradora de Ponte do Gama, a fala de que:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Eu vejo que
            <strong
              >as mulheres são muito participativas, mesmo com todas as outras
              jornadas que elas têm</strong
            >: família, filhos, marido, algumas trabalham fora. Eu, por exemplo,
            estudo. A gente se organiza de várias formas para dar conta de tudo.
            Tem uma frase que diz que o homem pode matar um leão, mas a mulher
            mata, tempera, cozinha, serve, lava a louça, ainda arruma a casa e
            vai cuidar do filho depois. Então é isso. Vejo a participação das
            mulheres e fico muito feliz com isso (LINO, 2019).”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="6" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A necessidade de justiça e o estado de luta permeiam todas as
                  idades, como a Sra. Leonina de Oliveira, de 82 anos, moradora
                  de Rio Doce, que narra que:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center border__image2"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “É importante ir nas reuniões. Nós vamos todas juntas: Deja,
            Terezinha, Martinha e eu. Nós fomos a Soberbo outro dia, saímos às
            oito horas e só cheguei em casa pra lá de oito da noite.
            <strong>Eu tô com 82 anos e não paro não, graças a Deus</strong>.
            Não gosto de ver as pessoas sofrendo. Se eu preciso, o outro também
            precisa (RODRIGUES et al., 2019).”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Assim, nomear as experiências das mulheres atingidas é
                  significativo para representar o histórico de luta que elas
                  têm enfrentado contra a permanente destruição de suas memórias
                  e tradições pelo rompimento da barragem de Fundão.
                </p>
                <p>
                  As mulheres fazem três vezes mais trabalho de cuidado não
                  remunerado do que os homens. Assim, mulheres realizam 75% do
                  trabalho não remunerado no mundo, como o cuidado das crianças,
                  de idosos e trabalhos domésticos (PEREZ, 2020). Esse cenário
                  mundial se replica de forma acentuada na conjuntura
                  brasileira, onde 90% do trabalho de cuidado são realizados,
                  informalmente, pelas famílias – e desses 90%, quase 85% é
                  feito por mulheres (TEMPO de cuidar, 2020).
                </p>
                <p>
                  Segundo a pesquisa “Sem parar: o trabalho e a vida das
                  mulheres na pandemia”, realizada pelas organizações Gênero e
                  Número e SOF – Sempreviva Organização Feminista, extraída do
                  jornal A Sirene de 2021, 50% das mulheres no Brasil passaram a
                  cuidar de alguém nesse período de isolamento. A situação é
                  ainda mais crítica para as mães: as que trabalham de forma
                  remota se ocupam do emprego, dos filhos e das tarefas da casa
                  ao mesmo tempo; e as que seguem em trabalhos presenciais
                  perderam o apoio das creches e escolas, que estão fechadas
                  (BENTO; TAVARES; SILVA, 2021).
                </p>
                <p>
                  Apesar dessa realidade em que as mulheres são protagonistas no
                  cuidado, os trabalhos domésticos realizados por elas não
                  recebem o devido reconhecimento em nossa sociedade machista.
                  Em Mariana, não poderia ser diferente. As mulheres são a força
                  motriz — econômica e social — da família, embora não estejam
                  sendo assim reconhecidas pelas mineradoras para reparação
                  integral dos danos <sup>5</sup>.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_04.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Daniela Felix, Eliane Balke e Paulo Daniel. Jornal A Sirene,
            2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A partir do cadastramento realizado pela Assessoria Técnica da
                  Cáritas foi possível ter dimensão de como o desastre-crime
                  atingiu as vidas dessas mulheres de maneira irreversível,
                  potencializando danos que a mineração já ocasionava e criando
                  outros ainda mais perversos. A forma como essas mulheres lidam
                  diariamente com as consequências desses danos perpassa suas
                  vivências de ser mulher, muitas vezes negra, atingidas pela
                  mineração e, por consequência, economicamente vulneráveis. A
                  transversalidade do patriarcado, do racismo e da divisão da
                  sociedade em classes deve ser central para esta análise.
                </p>
                <p>
                  A seguir, rememoramos alguns dos principais danos enfrentados
                  pelas mulheres atingidas, danos que são partilhados entre as
                  diversas idades, raças e vozes femininas das comunidades
                  atingidas. Os danos aqui trazidos guardam fortes relações com
                  as vivências mais marcantes trazidas nos relatos das mulheres
                  atingidas para a Cáritas Brasileira Regional Minas Gerais:
                  perda das relações comunitárias, dificuldades com a perda do
                  trabalho e com a diminuição da renda, as angústias para
                  recebimento do Auxílio Financeiro Emergencial (AFE) e os danos
                  suportados na Fase de Negociação Extrajudicial (FNE)<sup
                    >6</sup
                  >
                  para recebimento das indenizações.
                </p>
                <p>
                  As falas das mulheres atingidas aqui trazidas foram publicadas
                  no jornal A Sirene<sup>7</sup>, ou tiveram autorização para
                  publicação por meio de Termo de Consentimento Livre e
                  Esclarecido. Cumpre reforçar que as análises aqui esboçadas
                  sustentam-se nas percepções e reflexões elaboradas pela
                  própria Cáritas – Minas Gerais durante os mais de quatro anos
                  de trabalho em Mariana com as atingidas pelo rompimento da
                  Barragem de Fundão, em que foi possível observar padrões e
                  recorrências nas etapas de cadastramento ou nas reuniões de
                  atendimento jurídico. Assim, é forte a resistência por parte
                  das mulheres atingidas, que emprestam suas vozes e vivências
                  nesse documento para relatar as mazelas pós-desastre de tantas
                  outras mulheres atingidas pela mineração.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <div class="d-none d-sm-flex container-fluid arvores_bg"></div>
    <section style="">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="2"
          class="d-flex justify-center align-center ma-3 ml-md-12 pointer"
        >
          <p
            style="text-align: center; margin-bottom: 0; padding: 0.5em; background-color: #EDBD43;"
          >
            <router-link :to="{ name: 'conteudos_tematicos_rel_comunitarias' }"
              >Relações Comunitárias</router-link
            >
          </p>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="d-flex justify-center align-center ma-3 align-self-end pointer"
        >
          <p
            style="text-align: center; margin-bottom: 0; padding: 0.5em; background-color: #DC91B9;"
          >
            <router-link :to="{ name: 'conteudos_tematicos_trabalho_renda' }"
              >Trabalho e Renda</router-link
            >
          </p>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="d-flex justify-center align-center ma-3 pointer"
        >
          <p
            style="text-align: center; margin-bottom: 0; padding: 0.5em; background-color: #EDBD43;"
          >
            <router-link
              :to="{ name: 'conteudos_tematicos_auxilio_financeiro' }"
            >
              Auxílio Financeiro Emergencial</router-link
            >
          </p>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="d-flex justify-center align-center ma-3 pointer"
        >
          <p
            style="text-align: center; margin-bottom: 0; padding: 0.5em; background-color: #DC91B9;"
          >
            <router-link :to="{ name: 'conteudos_tematicos_indenizacoes' }"
              >Indenizações</router-link
            >
          </p>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="d-flex justify-center align-center ma-3 pointer"
        >
          <p
            style="text-align: center; margin-bottom: 0; padding: 0.5em; background-color: #EDBD43"
          >
            <router-link :to="{ name: 'conteudos_tematicos_garimpeiras' }"
              >Mulheres garimpeiras e pescadoras</router-link
            >
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex mt-5">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Acesse a reportagem do jornal A Sirene sobre os
              desafios das pescadoras atingidas:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/cultura-memoria/2020/07/27/oficio-marcado-no-corpo-a-vida-de-uma-pescadora-atingida"
                target="_blank"
                >https://jornalasirene.com.br/cultura-memoria/2020/07/27/oficio-marcado-no-corpo-a-vida-de-uma-pescadora-atingida.
              </a>
            </p>
            <p>
              <sup>2</sup> Acesse a reportagem do jornal A Sirene sobre as
              benzedeiras de Mariana e seus distritos:<a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/cultura-memoria/2018/03/08/benzedeiras-da-alma-do-corpo-do-coracao"
                target="_blank"
                >https://jornalasirene.com.br/cultura-memoria/2018/03/08/benzedeiras-da-alma-do-corpo-do-coracao.</a
              >
            </p>
            <p>
              <sup>3</sup> É célebre o fato de que as mulheres atingidas ocupam
              o maior espaço na formação da Comissão de Atingidos pela Barragem
              de Fundão (CABF). Das(os) dezesseis integrantes, nove são mulheres
              que lutam bravamente pelos direitos das atingidas de todas as
              comunidades. São elas: ngela Aparecido Lino, Cláudia Alves, Luzia
              Queiroz, Maria do Carmos D’Angelo, Maria José Horta Carneiro
              (Lilica), Marlene Agostinha Martins, Mirella Lino, Mônica dos
              Santos, Sônia Nazaré. Para mais informações sobre as mulheres da
              CABF, acesse:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/a-gente-explica/2021/10/19/nos-somos-a-comissao"
                target="_blank"
              >
                https://jornalasirene.com.br/a-gente-explica/2021/10/19/nos-somos-a-comissao.
              </a>
            </p>
            <p>
              <sup>4</sup> De acordo com os dados coletados pelo jornal “A
              Sirene” em 2019, “o protagonismo das mulheres no processo de
              reparação está presente não só entre as atingidas, mas também nas
              equipes de apoio. A Cáritas, Assessoria Técnica dos atingidos de
              Mariana, conta com uma equipe fixa formada por 30 pessoas e,
              dentro desse grupo, mais da metade são mulheres. Para a
              reformulação e a aplicação do Cadastro, foram contratadas 266
              pessoas e a maioria era composta por mulheres (196). Hoje, mesmo
              com uma equipe reduzida, o Cadastro ainda é fortemente integrado
              por elas: das 123 pessoas, 97 são mulheres. Na AEDAS, Assessoria
              Técnica de Barra Longa, há uma equipe fixa de mobilizadores e
              técnicos que conta com 13 pessoas, das quais, 10 são mulheres.“
              (RODRIGUES et al., 2019).
            </p>
            <p>
              <sup>5</sup> A desconsideração dessa realidade acontece
              principalmente na Fase de Negociação Extrajudicial (FNE), onde os
              valores indenizatórios não levam em conta a realidade local. A FNE
              é o espaço gerido e administrado pela Fundação Renova com vistas à
              indenização extrajudicial dos danos às pessoas atingidas. Para
              mais informações, acesse:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf"
                target="_blank"
              >
                http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf.</a
              >
            </p>
            <p>
              <sup>6</sup> Espaço gerido e administrado pela Fundação Renova com
              vistas à indenização extrajudicial dos danos às pessoas atingidas.
              Para mais informações, acesse:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf"
                target="_blank"
              >
                http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf.
              </a>
            </p>
            <p>
              <sup>7</sup> Veículo de comunicação das(os) atingidas(os) pela
              barragem de Fundão. Para não esquecer! Acesse:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/"
                target="_blank"
              >
                https://jornalasirene.com.br/.
              </a>
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container pb-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p><strong>Referências Bibliográficas</strong></p>

            <p>
              BALKE, Eliane. Ofício marcado no corpo: a vida de uma pescadora
              atingida. <b>A Sirene</b>, Mariana, jul. 2020. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/cultura-memoria/2020/07/27/oficio-marcado-no-corpo-a-vida-de-uma-pescadora-atingida"
                target="_blank"
              >
                https://jornalasirene.com.br/cultura-memoria/2020/07/27/oficio-marcado-no-corpo-a-vida-de-uma-pescadora-atingida.</a
              >
              Acesso em: 14 fev. 2022.
            </p>

            <p>
              BENTO, Beatriz Helena; TAVARES, Fabrícia Maria Machado; SILVA,
              Simone. A sobrecarga de trabalho das mães atingidas.
              <b>A Sirene</b>, Mariana, mai. 2021. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/cotidiano/2021/05/08/a-sobrecarga-de-trabalho-das-maes-atingidas"
                target="_blank"
                >https://jornalasirene.com.br/cotidiano/2021/05/08/a-sobrecarga-de-trabalho-das-maes-atingidas.</a
              >
              Acesso em: 14 fev. 2022.
            </p>

            <p>
              CÁRTIAS BRASILEIRA REGIONAL MINAS GERAIS.
              <b
                >Teses acerca do instituto da prescrição da pretensão de
                reparação dos danos decorrentes do rompimento da barragem de
                Fundão</b
              >: uma análise necessária à luz dos Direitos Humanos. Mariana,
              2021. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/October2021/WqfZ3THbCHQMpDkY4gC8.pdf"
                target="_blank"
                >http://mg.caritas.org.br/storage/arquivo-de-biblioteca/October2021/WqfZ3THbCHQMpDkY4gC8.pdf. </a
              >Acesso em: 14 fev. 2022.
            </p>

            <p>
              CÁRITAS BRASILEIRA REGIONAL MINAS GERAIS.
              <b>Fase de Negociação Extrajudicial</b>: empecilho à reparação
              integral? Mariana, 2021. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="http://mg.caritas.org.br/noticias/fase-de-negociacao-extrajudicial-empecilho-a-reparacao-integral"
                target="_blank"
                >http://mg.caritas.org.br/noticias/fase-de-negociacao-extrajudicial-empecilho-a-reparacao-integral. </a
              >Acesso em: 14 fev. 2022.
            </p>

            <p>
              CASTRO, Dalva Xavier et al. Não reconhecidas. <b>A Sirene</b>,
              Mariana, out. 2018. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/justica/2018/10/04/nao-reconhecidas"
                target="_blank"
              >
                https://jornalasirene.com.br/justica/2018/10/04/nao-reconhecidas. </a
              >Acesso em: 14 fev. 2022.
            </p>

            <p>
              LINO, Mirella et al. “Ser mulher é uma luta”. <b>A Sirene</b>,
              [Mariana], mar. 2019. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/cultura-memoria/2019/03/08/ser-mulher-e-uma-luta"
                target="_blank"
                >https://jornalasirene.com.br/cultura-memoria/2019/03/08/ser-mulher-e-uma-luta. </a
              >Acesso em: 14 fev. 2022.
            </p>

            <p>
              MINISTÉRIO DA CIDADANIA. Bolsa família garante direitos e
              autonomia às mulheres.
              <b>Secretaria Especial do Desenvolvimento Social</b>, Brasília,
              2016. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="http://mds.gov.br/area-de-imprensa/noticias/2016/marco/bolsa-familia-garante-direitos-e-autonomia-as-mulheres"
                target="_blank"
                >http://mds.gov.br/area-de-imprensa/noticias/2016/marco/bolsa-familia-garante-direitos-e-autonomia-as-mulheres.</a
              >
              Acesso em: 14 fev. 2022.
            </p>

            <p>
              NÓS somos a comissão. <b>A Sirene</b>, Mariana, out. 2021.
              Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/a-gente-explica/2021/10/19/nos-somos-a-comissao"
                target="_blank"
                >https://jornalasirene.com.br/a-gente-explica/2021/10/19/nos-somos-a-comissao. </a
              >Acesso em: 14 fev. 2022.
            </p>

            <p>
              PAPAGAIO, Sergio. Papo de comadres: a fome do corpo e a fome da
              alma. <b>A Sirene</b>, Mariana, ano 6, ed. 66, p. 3, out. 2021.
              Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://issuu.com/jornalasirene/docs/a_sirene_edicao_66_outubro_2021"
                target="_blank"
                >https://issuu.com/jornalasirene/docs/a_sirene_edicao_66_outubro_2021. </a
              >Acesso em: 14 fev. 2022.
            </p>

            <p>
              PEREZ, Caroline Criado. <b>La mujer invisible</b>: descubre cómo
              los datos configuran un mundo hecho por y para los hombres.
              Editora Seix Barral, Barcelona, 2020.
            </p>

            <p>
              RODRIGUES, Carolina et al. “Ser mulher é uma luta”.
              <b>A Sirene</b>, [Mariana], mar. 2019. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/cultura-memoria/2019/03/08/ser-mulher-e-uma-luta"
                target="_blank"
                >https://jornalasirene.com.br/cultura-memoria/2019/03/08/ser-mulher-e-uma-luta.
              </a>
              Acesso em: 14 fev. 2022.
            </p>

            <p>
              SENA, Maria do Carmo. Mães atingidas. <b>A Sirene</b>, Mariana,
              mai. 2019. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/cotidiano/2019/05/12/maes-atingidas"
                target="_blank"
                >https://jornalasirene.com.br/cotidiano/2019/05/12/maes-atingidas. </a
              >Acesso em: 14 fev. 2022.
            </p>

            <p>
              TEMPO de cuidar. <b>Oxfam Brasil</b>, São Paulo, 2020. Disponível
              em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://www.oxfam.org.br/justica-social-e-economica/forum-economico-de-davos/tempo-de-cuidar/"
                target="_blank"
                >https://www.oxfam.org.br/justica-social-e-economica/forum-economico-de-davos/tempo-de-cuidar/. </a
              >Acesso em: 14 fev. 2020.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Gênero /",
          href: "conteudos_tematicos_genero",
        },
        {
          text: "Apresentação",
          href: "conteudos_tematicos_genero",
        },
      ],
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}

.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .title__img {
    width: 50%;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
